<template>
  <div>
    <!-- 页面内容 -->
    <transition name="fade-three" mode="out-in">
      <router-view v-if="show" />
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: true,
    }
  },
  created() {},
  methods: {},
}
</script>
<style lang='scss' scoped>
</style>
